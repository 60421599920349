import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import { SUBSCRIBE_PATH } from '~utils/subscribe-helpers';

const seo = {
  seo_title: 'Register',
  seo_description: 'Sign-up to get free access to Aleph Beta’s Torah videos.',
  seo_keywords: 'register aleph beta'
};

const Register = () => {
  useEffect(() => {
    navigate(SUBSCRIBE_PATH);
  }, []); // eslint-disable-line

  return (
    <Page>
      <SEO {...seo} />
    </Page>
  );
};

export default Register;
